#sidebar {
    background-color: var(--bg-dark-color);
    position: fixed;
}

#sidebar .navigation-item .active {
    color: var(--primary-color);
}

#sidebar .ex-nav .ex-nav--item+.ex-nav--item {
    margin-top: .5rem;
}

#sidebar .logo {
    font-size: x-large;
}

#sidebar .brand-thumb {
    text-decoration: none;
}