@import url("https://use.fontawesome.com/releases/v5.0.11/css/all.css");
@import url('./Assets/css/animate.css');

@font-face {
  font-family: Halimum;
  src: url('./Assets/fonts/Halimum.ttf');
}

@font-face {
  font-family: Laillaland;
  src: url('./Assets/fonts/Laillaland.ttf');
}

:root {
  --primary-color: #00BCD4;
  /* --primary-color: #6D9886; */
  --primary-trans-color: #00BCD42c;
  --secondary-color: #b9b9b9;
  --bg-color: #1d1d1d;
  --bg-color-maintaince: #E8EAEA;
  --bg-dark-color: #181818;
  --header-font-color: #4d4d4e;
  --bg-header-logo: #070707;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
}

.maintainance {
  background-color: var(--bg-color-maintaince) !important;
}

.head {
  font-family: Laillaland !important;
}

.special-text {
  font-family: Halimum !important;
}

#mainWrapper {
  margin-left: 10vh;
}

@media only screen and (max-width: 500px) {
  #mainWrapper {
    margin-left: 0vh;
    margin-bottom: 5vh;
  }

  #pageHead {
    /* display: none; */
  }
}